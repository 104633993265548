<template>
  <div class="">
    <div class="text-right">
      <CButton color="primary" to="/tfw">Back</CButton>
    </div>
    <CRow class="mt-2">
      <CCol md="4" v-for="(item, index) of items" :key="index">
        <CCard class="course-card">
          <div class="p-3">
            <h6 class="text-2-truncate title px-1" :title="item.title">
              {{ item.title }}
            </h6>
          </div>
          <img class="img-card" :src="item.image" />
          <CCardBody class="p-2 position-relative h-100">
            <div>
              <div class="container">
                <div class="col-12">
                  <div class="row">
                    <div class="col-md-2 font-weight-bold p-0">Hospital</div>
                    <div class="col-md-9 p-0">:&nbsp;&nbsp;{{ item.hospital }}</div>
                  </div>
                  <div class="row">
                    <div class="col-md-2 font-weight-bold p-0">Type</div>
                    <div class="col-md-9 p-0">:&nbsp;&nbsp;{{ item.type }}</div>
                  </div>
                  <div class="row">
                    <div class="col-md-2 font-weight-bold p-0">Period</div>
                    <div class="col-md-9 p-0">:&nbsp;&nbsp;{{ item.period }}</div>
                  </div>
                  <div class="row">
                    <div class="col-md-2 font-weight-bold p-0">Start Date</div>
                    <div class="col-md-9 p-0">:&nbsp;&nbsp;{{ item.start_date }}</div>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end">
                <CButton
                  size="sm"
                  color="info"
                  class="bg-modal-primary p-2"
                  to="/tfw/placements/view"
                  >View Details</CButton
                >
              </div>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
export default {
  data() {
    return {
      items: [
        {
          image: "/img/tfw/placements/placement_1.png",
          title: "Practice Learning Placement Year 1 - Mental Health Nursing",
          hospital: "York Care Home",
          type: "3yr - Adult Nursing",
          period: "8 weeks",
          start_date: "5th May 2024",
        },
        {
          image: "/img/tfw/placements/placement_2.png",
          title: "Practice Learning Placement Year 2 - Children's Nursing",
          hospital: "Children's General",
          type: "3yr - Adult Nursing",
          period: "6 weeks",
          start_date: "5th May 2024",
        },
        {
          image: "/img/tfw/placements/placement_3.png",
          title: "Practice Learning Placement Year 3 - Adult Nursing",
          hospital: "St. James General",
          type: "3yr - Adult Nursing",
          period: "8 weeks",
          start_date: "5th May 2024",
        },
      ],
    };
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
.text-2-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.title {
  font-weight: 700;
  line-height: 1.235;
  font-size: 22px;
}

.img-card {
  height: 200px;
}

.course-card {
  min-height: 250px;
}

.triple-space::after {
  content: "\00a0\00a0\00a0";
}

// .course-footer {
//   bottom: 5px;
// }
// .h-80 {
//   height: 90px;
// }
</style>
